html,
body {
  height: 100%;
  overflow: visible;
  outline: none;
  box-shadow: none;
}

.routerLink {
  margin: 0;
  padding: 0 !important;
}

.textareaDark::placeholder {
  color: #ccc;
}
.textareaNorm::placeholder {
  color: "#121212";
}

.button {
  min-width: 300px;
  max-width: 400px;
  font-weight: 600;
  border: none !important;
}
a:hover {
  background-color: transparent !important;
}

label {
  display: flex !important;
  flex-direction: column;
  align-items: center;
}

.inputDark::placeholder {
  color: #ccc;
}
.input::placeholder {
  color: "#121212";
}

.progress {
  width: 100%;
  max-width: 400px;
  
}
.progress.is-primary.dark:indeterminate {
    background-image: linear-gradient(to right,#00d1b2 30%,#242222 30%);
}
@media (max-width: 600px) {
    .progress {
        max-width: 250px;
    }
  }
.w-100 {
  width: 100% !important;
}
.vw-100 {
  width: 100vw !important;
}

.react-datepicker__navigation--previous{
  min-width: none !important;
}
.react-datepicker__navigation--next{
  min-width: none !important;
}
